export const components = {
  notificationPopover: {
    title: 'Уведомления',
    switchText: 'Показать только непрочитанные',
    dividerToday: 'СЕГОДНЯ',
    dividerYesterday: 'ВЧЕРА',
    dividerEarlier: 'РАНЕЕ',
    markUnreadButton: 'Отметить как непрочитанное',
    empty: {
      title: 'Уведомлений пока что нет',
      description: 'Когда ты получишь уведомления, они будут здесь',
    },
    modal: {
      button: 'Ок, хорошо',
    },
  },
  accountPopover: {
    logout: 'Выйти',
    home: 'Домой',
  },
  languagePopover: {
    en: 'US English',
    ru: 'Русский',
    ua: 'Украинский',
  },
  modal: {
    confirm: 'Подтвердить',
    cancel: 'Отменить',
  },
};
