import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NotFoundLayout } from 'gradual-system';

import { ROUTE_PATHS } from '~app/routes/paths';

import notFoundImg from './course-not-found.png';

export const CourseNotFound = () => {
  const { t } = useTranslation('course', { keyPrefix: 'notFound' });
  const navigate = useNavigate();

  return (
    <NotFoundLayout
      withLayout
      imageSrc={notFoundImg}
      width="400px"
      title={t('title')}
      description={t('description')}
      linkTo={() => navigate(ROUTE_PATHS.INDEX)}
      buttonText={t('button')}
    />
  );
};
