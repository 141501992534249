export const module = {
  quiz: {
    title: 'Тест',
    completed: 'Завершен',
    failed: 'Провален',
    buttonStart: 'Начать',
    tryAgainButton: 'Пересдать',
    continueButton: 'Продолжить',
    newStatus: 'Новый',
    minutes: '{{min}} мин.',
    question: 'Вопросов: {{questionsLength}} ',
    notFound: {
      title: 'Тест не найден',
      description: 'Пожалуйста, проверь ссылку или напиши админу',
    },
    inProgress: 'В прогрессе',
  },
  task: {
    title: 'Задача',
    submitted: 'Сдана',
    buttonStart: 'Начать',
    tryAgainButton: 'Пересдать',
    newStatus: 'Новая',
    notFound: {
      title: 'Задача не найдена',
      description: 'Пожалуйста, проверь ссылку или напиши админу',
    },
  },
  notFound: {
    title: 'Модуль не найден',
    description: 'Пожалуйста, проверь ссылку или напиши админу',
    button: 'Домой',
  },
  buttons: {
    delay: 'Следующий модуль будет доступен через {{daysText}} {{hoursText}} {{minutesText}} {{secondsText}}',
    days: '{{days}} дней',
    hours: '{{hours}} часов',
    minutes: '{{minutes}} минут',
    seconds: '{{seconds}} секунд',
    prev: '< Предыдущий',
    next: 'Следующий >',
    finish: 'Закончить тренинг',
    view: 'Посмотреть сертификат',
  },
  pdfPanel: {
    of: 'of',
    button: 'Открыть',
    fullSize: 'Открыть в полном размере',
  },
  noContent: 'Кажется, контента нет',
  navbar: 'Модуль',
  toast: {
    error: 'Перезагрузите страничку, чтобы обновить тренинг',
  },
};
