import { ReactNode } from 'react';
import { AuthProvider as GradualAuthProvider, AxiosInterceptors } from 'gradual-system';

import { AuthApi } from '~modules/auth/authApi';
import axios from '~utils/axios';

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  return (
    <GradualAuthProvider
      onRefreshUserToken={AuthApi.refreshUserToken}
      onLogin={AuthApi.loginUser}
      onGetUser={AuthApi.getUser}
    >
      <AxiosInterceptors axios={axios} />
      {children}
    </GradualAuthProvider>
  );
};
