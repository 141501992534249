export const quiz = {
  prevButton: 'Попреднє питання',
  mobilePrevButton: 'Назад',
  mobileNextButton: 'Вперед',
  mobileFinishButton: 'Завершити',
  finishButton: 'Завершити тест',
  nextButton: 'Наступне питання',
  description: 'Питання {{questionNumber}}',
  of: 'з {{questionsLength}}',
  info: {
    title: 'Інфо',
    descriptionPassing: 'Пороговий бал',
    descriptionAttempts: 'Кількість спроб',
    descriptionAttemptsMobile: 'Спроби',
    descriptionTime: 'Залишилось часу',
  },
  navigation: {
    title: 'Навігація',
  },
  modal: {
    success: 'Успішно',
    failed: 'Провалений',
    score: 'Твій бал',
    attemptsLeft: 'Залишилось спроб',
    timeSpent: 'Витрачено часу',
    tryAgainButton: 'Спробувати ще раз',
    backButton: 'До модулів',
    goodJob: 'Гарна робота',
  },
  tabs: {
    question: 'Питання',
    navigation: 'Навігація',
  },
  intro: {
    title: 'Огляд',
    description: 'Огляд тесту',
    passingScore: 'Пороговий бал',
    requiresPassingScore: 'Необхідний пороговий бал',
    attempts: 'Кількість спроб',
    questionsLength: 'Кількість питань',
    button: 'Розпочати тест',
    backButton: 'До модулів',
    descriptionTime: 'Обмеження часу',
  },
  openAnswer: {
    placeholder: 'Твоя відповідь',
  },
  toastSuccess: 'Тест завершений',
  toastError: 'Ой! щось пішло не так...',
};
