/* eslint-disable no-promise-executor-return */
import { ACCESS_TOKEN_LS_KEY } from '~constants';
import axios from '~utils/axios';

import { AuthTokens, User } from './authTypes';

const URL = '/learner/auth';

function loginUser({ login, password }: { login: string; password: string }) {
  return axios.post<AuthTokens>(`${URL}/signin`, {
    login,
    password,
  });
}

function signUp({
  firstName,
  lastName,
  login,
  password,
  invitationToken,
}: {
  firstName: string;
  lastName: string;
  login: string;
  password: string;
  invitationToken: string;
}) {
  return axios.post<void>(`${URL}/signup`, {
    firstName,
    lastName,
    login,
    password,
    invitationToken,
  });
}

function getUser() {
  return axios.get<User>('/learner/info', {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_LS_KEY)}`,
    },
  });
}

function refreshUserToken({ refreshToken }: { refreshToken: string }) {
  return axios.post<AuthTokens>(`${URL}/refresh-token`, {
    refreshToken,
  });
}

function resetPassword({ email }: { email: string }) {
  return axios.post(`${URL}/reset-password`, { email });
}

function setNewPassword({ password, secretToken }: { password: string; secretToken: string }) {
  return axios.post(`${URL}/set-password`, { newPassword: password, secretToken });
}

function verifyLogin({ login }: { login: string }) {
  return axios.post(`${URL}/verify`, { login });
}

export const AuthApi = { loginUser, getUser, refreshUserToken, signUp, resetPassword, setNewPassword, verifyLogin };
