import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, useThemeMode } from 'gradual-system';

import { Box, Button, Stack, Typography } from '@mui/material';

import defaultLogoDark from '~components/Header/dark-logo.svg';
import defaultLogoWhite from '~components/Header/white-logo.svg';
import { useCompany } from '~hooks/useCompany';

const OopsPage = () => {
  const { adminEmail } = useCompany();
  const branding = useCompany();
  const { themeMode } = useThemeMode();

  const { t } = useTranslation('oops');
  const defaultLogo = themeMode === 'light' ? defaultLogoDark : defaultLogoWhite;

  return (
    <Box>
      <Stack
        spacing={5}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height="100vh"
        px={10}
        textAlign="center"
      >
        <Box mb={9}>
          <Image
            src={branding.logo ? branding.logo : defaultLogo}
            sx={{
              maxWidth: '150px',
            }}
          />
        </Box>
        <Typography variant="h1">{t('title')}</Typography>
        <Typography variant="text1">{t('description1')}</Typography>

        <Typography display="block" variant="text1" textAlign="center">
          {t('description2')}
        </Typography>

        <Button href={`mailto:${adminEmail}`} size="large">
          {t('link')}
        </Button>
      </Stack>
    </Box>
  );
};

export default OopsPage;
