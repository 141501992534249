export const task = {
  description: 'Описание:',
  yourAnswer: 'Твой ответ:',
  answerPlaceholder: 'Напиши что-то...',
  uploadedFiles: 'Загруженные файлы:',
  submitButton: 'Отправить',
  uploadButton: 'Загрузить файл',
  errorText: 'Некоторые файлы весят больше 1GB',
  toastSuccess: 'Задача отправлена',
  toastError: 'Ой! что-то пошло не так...',
  toastEmpty: 'Нужно ответить или прикрепить файл',
};
