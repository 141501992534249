import { ReactNode } from 'react';

import { LinkProps, ListItemButton, ListItemButtonProps, ListItemIcon } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import {
  NAVBAR_LIST_ITEM_ACTIVE_OPACITY,
  NAVBAR_LIST_ITEM_ACTIVE_OPACITY_HOVER,
  NAVBAR_LIST_ITEM_ICON_SIZE,
} from './navbarConstants';

type Props = LinkProps & ListItemButtonProps;

interface StyledNavbarListItemProps extends Props {
  component?: ReactNode;
  to?: string;
  activeRoot?: boolean;
}

export const StyledNavbarListItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'activeRoot',
})<StyledNavbarListItemProps>(({ activeRoot, theme }) => ({
  ...theme.typography.text2,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(9),
  paddingRight: theme.spacing(6),
  color: theme.palette.text.secondary,
  borderRight: '4px solid',
  borderColor: 'transparent',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, NAVBAR_LIST_ITEM_ACTIVE_OPACITY_HOVER),
  },
  // activeRoot
  ...(activeRoot && {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, NAVBAR_LIST_ITEM_ACTIVE_OPACITY),
    borderRight: '4px solid',
    borderColor: 'primary.main',
  }),
}));

export const StyledNavbarListItemIcon = styled(ListItemIcon)({
  width: NAVBAR_LIST_ITEM_ICON_SIZE,
  height: NAVBAR_LIST_ITEM_ICON_SIZE,
  minWidth: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': { width: '100%', height: '100%' },
});
