export const quiz = {
  prevButton: 'Предыдущий вопрос',
  mobilePrevButton: 'Назад',
  mobileNextButton: 'Вперед',
  mobileFinishButton: 'Завершить',
  finishButton: 'Завершить тест',
  nextButton: 'Следующий вопрос',
  description: 'Вопрос {{questionNumber}}',
  of: 'из {{questionsLength}}',
  info: {
    title: 'Инфо',
    descriptionPassing: 'Пороговый балл',
    descriptionAttempts: 'Количество попыток',
    descriptionAttemptsMobile: 'Попытки',
    descriptionTime: 'Осталось времени',
  },
  navigation: {
    title: 'Навигация',
  },
  modal: {
    success: 'Успешно',
    failed: 'Провалено',
    score: 'Твой результат',
    attemptsLeft: 'Осталось попыток',
    timeSpent: 'Время прохождения',
    tryAgainButton: 'Попробовать еше раз',
    backButton: 'Назад к модулям',
    goodJob: 'Хорошая работа',
  },
  tabs: {
    question: 'Вопрос',
    navigation: 'Навигация',
  },
  intro: {
    title: 'Обзор',
    description: 'Обзор теста',
    passingScore: 'Пороговый балл',
    requiresPassingScore: 'Нужен пороговый балл',
    attempts: 'Количество попыток',
    questionsLength: 'Количество вопросов',
    button: 'Начать тест',
    backButton: 'Назад к модулям',
    descriptionTime: 'Ограничение по времени ',
  },
  openAnswer: {
    placeholder: 'Твой ответ',
  },
  toastSuccess: 'Тест закончен',
  toastError: 'Ой! что-то пошло не так...',
};
