export const course = {
  navbar: {
    title: 'Модулі',
  },
  notFound: {
    title: 'Тренінгу не знайдено',
    description: 'Будь ласка, перевір посилання або сконтактуй адміна',
    button: 'Додому',
  },
  overview: {
    buttonBack: 'Назад до всіх тренінгів',
    title: 'Про цей тренінг',
    buttonBegin: 'Розпочати тренінг',
    modules: 'Кількість модулів',
    quizzes: 'Кількість тестів',
    tasks: 'Кількість задач',
  },
  finished: {
    title: 'Ти завершив тренінг!',
    modules: 'Модулів завершено',
    quiz: 'Середній результат тестів',
    time: 'Час проходження',
    titleSocialMobile: 'Поділитись у соц.мережах',
    titleSocial: 'Поділитись сертифікатом у соц.мережах',
    titleDownload: 'Завантажити .pdf версію сертифікату',
    titleDownloadMobile: 'Завантажити .pdf',
    button: 'Переглянути модулі',
  },
};
