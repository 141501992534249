export const module = {
  quiz: {
    title: 'Quiz',
    completed: 'Completed',
    failed: 'Failed',
    buttonStart: 'Start',
    tryAgainButton: 'Try again',
    continueButton: 'Continue',
    newStatus: 'New',
    minutes: '{{min}} min',
    question: 'Questions: {{questionsLength}}',
    notFound: {
      title: 'Quiz not found',
      description: 'Please, check your link or contact our manager',
    },
    inProgress: 'In progress',
  },
  task: {
    title: 'Task',
    submitted: 'Submitted',
    buttonStart: 'Start',
    tryAgainButton: 'Try again',
    newStatus: 'New',
    notFound: {
      title: 'Task not found',
      description: 'Please, check your link or contact our manager',
    },
  },
  notFound: {
    title: 'Module not found',
    description: 'Please, check your link or contact your admin',
    button: 'Back to Home',
  },
  buttons: {
    delay: 'Next module will be available in {{daysText}} {{hoursText}} {{minutesText}} {{secondsText}}',
    days: '{{days}} days',
    hours: '{{hours}} hours',
    minutes: '{{minutes}} minutes',
    seconds: '{{seconds}} seconds',
    prev: '< Prev module',
    next: 'Next module >',
    finish: 'Finish course',
    view: 'View certificate',
  },
  pdfPanel: {
    of: 'of',
    button: 'Open',
    fullSize: 'Open full size',
  },
  noContent: 'Seems like no content here',
  navbar: 'Module',
  toast: {
    error: 'Refresh the page to update training',
  },
};
