import { ReactNode } from 'react';
import { GradualThemeProvider } from 'gradual-system';

import { useCompany } from '~hooks/useCompany';

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const { color } = useCompany();

  return <GradualThemeProvider primaryColor={color}>{children}</GradualThemeProvider>;
};
