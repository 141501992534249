export const components = {
  notificationPopover: {
    title: 'Notifications',
    switchText: 'Show only unread',
    dividerToday: 'TODAY',
    dividerYesterday: 'YESTERDAY',
    dividerEarlier: 'EARLIER',
    markUnreadButton: 'Mark as unread',
    empty: {
      title: 'No notifications yet',
      description: 'When you get notifications, they’ll show up here',
    },
    modal: {
      button: 'Ok, thanks!',
    },
  },
  accountPopover: {
    logout: 'Logout',
    home: 'Home',
  },
  languagePopover: {
    en: 'US English',
    ru: 'Russian',
    ua: 'Ukrainian',
  },
  modal: {
    confirm: 'Confirm',
    cancel: 'Cancel',
  },
};
