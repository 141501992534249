import { useLocation } from 'react-router-dom';

import { NavbarListItem } from './NavbarListItem';
import { NavbarConfigItem } from './navbarTypes';

export const NavbarList = ({ list }: { list: NavbarConfigItem[] }) => {
  const { pathname } = useLocation();

  return (
    <>
      {list.map((item) => (
        <NavbarListItem item={item} key={item.title} active={item.path === pathname} />
      ))}
    </>
  );
};
