export const task = {
  description: 'Description:',
  yourAnswer: 'Your answer:',
  answerPlaceholder: 'Type something here...',
  uploadedFiles: 'Uploaded files:',
  submitButton: 'Submit',
  uploadButton: 'Upload file',
  errorText: 'Some files exceeded 1gb',
  toastSuccess: 'Task is submitted',
  toastError: 'Oops! something went wrong...',
  toastEmpty: 'Need to answer or attach a file',
};
