export const auth = {
  shared: {
    phoneLabel: 'Номер телефону',
    emailLabel: 'Email адреса',
    passwordLabel: 'Пароль',
    backToSignIn: 'Повернутись до входу',
  },
  validation: {
    emailRequired: 'Необхідний Email',
    emailValid: 'Необхідна валідна адреса',
    lastNameRequired: 'Бракує прізвища',
    nameRequired: 'Бракує імені',
    passwordConfirmationRequired: 'Підтвердіть пароль',
    passwordLength: 'Має бути щонайменше {{minPasswordLength}} символів',
    passwordRequired: 'Необхідний пароль',
    passwordsMatch: 'Паролі не співпадають',
    phoneValid: 'Неправильний номер телефону',
    phoneRequired: 'Необхідний номер телефону',
  },
  login: {
    description: 'Немає акаунту?',
    forgotPassword: 'Забули пароль?',
    signUp: 'Зареєструватись',
    submitButton: 'Увійти',
    title: 'Вітаємо у {{name}}',
    titleDefault: 'Вітаємо у Gradual',
    training: 'Тренінг!',
    subtitle: 'Введіть електронну адресу та пароль для доступу до свого облікового запису',
  },
  signUp: {
    buttonNext: 'Наступний крок',
    confirmPasswordLabel: 'Підтверди пароль',
    descriptionInfo: "Напиши ім'я та прізвище",
    descriptionPassword: 'Має бути щонайменше {{minPasswordLength}} символів',
    haveAccount: 'Уже маєш аккаунт?',
    lastNameLabel: 'Прізвище',
    nameLabel: "Ім'я",
    submitButton: 'Розпочати',
    titleEmail: 'Введи Email',
    titlePhone: 'Введи номер телефону',
    titleInfo: "Введи ім'я та прізвище",
    titlePassword: 'Обери пароль',
    signIn: 'Вхід',
  },
  forgotPassword: {
    description: 'Введи Email адресу акаунту. Ми надішлемо посилання для відновлення паролю.',
    submitButton: 'Відновити пароль',
    textSuccess: 'Запит успішно надісланий',
    descriptionSuccess: 'Ми надіслали підтвердження. Будь ласка, перевір поштову скриньку.',
    title: 'Забули пароль?',
  },
  resetPassword: {
    description: 'Обери пароль, який не використовувався раніше',
    newPasswordConfirmLabel: 'Підтвердь новий пароль',
    newPasswordLabel: 'Новий пароль',
    submitButton: 'Отримати новий пароль',
    title: 'Новий пароль',
    textSuccess: 'Пароль відновлено',
    descriptionSuccess: 'Твій пароль був успішно відновлений. Натисни нижче для входу.',
  },
};
