export const ROUTE_PATHS = {
  INDEX: '/',
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  QUIZ: '/quiz/:quizId',
  TASK: '/task/:taskId',
  COURSE: '/courses/:courseId',
  MODULE: '/courses/:courseId/modules/:moduleId',
  NOT_FOUND: '/404',
  INVALID_COMPANY: '/invalid-company',
  COURSE_RESULT: '/courses/:courseId/result',
};
