export const courses = {
  title: 'Мої тренінги',
  empty: {
    title: 'У тебе ще немає тренінгів',
  },
  banner: {
    title: 'Раді вітати, {{firstName}}',
    description: 'У тебе є нові тренінги. Натисніть на картку нижче, щоб почати.',
  },

  courseCard: {
    buttonStart: 'Розпочати',
    buttonReview: 'Переглянути',
    buttonContinue: 'Продовжити',
    module: 'Модулів: {{count}}',
  },
};
