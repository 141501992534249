import { useNavigate } from 'react-router-dom';
import { Header as GradualHeader, HeaderLogo, ThemeModeToggle } from 'gradual-system';

import { ROUTE_PATHS } from '~app/routes/paths';
import { useCompany } from '~hooks/useCompany';

import { LanguagePopover } from './LanguagePopover/LanguagePopover';
import { AccountPopover } from './AccountPopover';
import defaultLogoDark from './dark-logo.svg';
import defaultLogoWhite from './white-logo.svg';

interface HeaderProps {
  onOpenMobileSidebar?: VoidFunction;
  withMobileNavbar?: boolean;
}

export const Header = ({ onOpenMobileSidebar, withMobileNavbar }: HeaderProps) => {
  const { logo } = useCompany();
  const navigate = useNavigate();

  return (
    <GradualHeader
      onOpenMobileSidebar={onOpenMobileSidebar}
      withMobileNavbar={withMobileNavbar}
      toolbar={
        <>
          <LanguagePopover />
          <ThemeModeToggle />
          <AccountPopover />
        </>
      }
      logo={
        <HeaderLogo
          onClick={() => navigate(ROUTE_PATHS.INDEX)}
          darkLogo={logo || defaultLogoDark}
          lightLogo={logo || defaultLogoWhite}
        />
      }
    />
  );
};
