import axios from '~utils/axios';

import { Course, CourseCardItem, CourseFinishProps, CourseResult } from './courseTypes';

const URL = '/learner/courses';

function getCourses() {
  return axios.get<CourseCardItem[]>(URL);
}

function getCourse({ courseId }: { courseId: string }) {
  return axios.get<Course>(`${URL}/${courseId}`);
}

function getCourseResult(courseId: string) {
  return axios.get<CourseResult>(`${URL}/${courseId}/result`);
}

function finishCourse({ id, moduleId }: CourseFinishProps) {
  return axios.post<Course>(`${URL}/${id}`, { moduleId });
}

export const CourseApi = { getCourse, getCourses, getCourseResult, finishCourse };
