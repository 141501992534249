import axios, { AxiosError } from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
});

export const isAxiosError = (error: unknown): error is AxiosError => {
  return (error as AxiosError).isAxiosError;
};

export default axiosInstance;
