import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Outlet, useLocation, useParams } from 'react-router-dom';
import { Loader, StyledLayoutMain } from 'gradual-system';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Box } from '@mui/material';

import { ROUTE_PATHS } from '~app/routes/paths';
import { Header } from '~components/Header/Header';
import { Navbar } from '~modules/module/navbar/Navbar';

import { CourseNotFound } from '../course/course/CourseNotFound/CourseNotFound';
import { useCourse } from '../course/course/useCourse';

import { NavbarConfigItem } from './navbar/navbarTypes';

export const ModuleLayout = () => {
  const { courseId = '' } = useParams();

  const [mobileSidebarOpened, setMobileSidebarOpened] = useState(false);
  const { pathname } = useLocation();
  const { course, courseLoading, finishCourse } = useCourse(courseId);
  const { t } = useTranslation('module');
  const withMobileNavbar = pathname.includes('/modules');

  const getNavbarConfigIcon = useCallback((state) => {
    if (state === 'CLOSED') {
      return <LockIcon color="disabled" />;
    }
    if (state === 'FINISHED') {
      return <CheckCircleIcon sx={{ color: 'success.dark' }} />;
    }
    return <LockOpenIcon sx={{ color: 'primary.light' }} />;
  }, []);

  const navbarConfig: NavbarConfigItem[] = useMemo(
    () =>
      course?.modules?.map((module) => ({
        title: module.name ? module.name : t('navbar'),
        path: generatePath(ROUTE_PATHS.MODULE, {
          courseId: course.id.toString(),
          moduleId: module.id.toString(),
        }),
        icon: getNavbarConfigIcon(module.state),
        disabled: module.state === 'CLOSED',
      })) || [],
    [getNavbarConfigIcon, course, t],
  );

  if (courseLoading) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Header onOpenMobileSidebar={() => setMobileSidebarOpened(true)} withMobileNavbar={withMobileNavbar} />
      {!course ? (
        <StyledLayoutMain>
          <CourseNotFound />
        </StyledLayoutMain>
      ) : (
        <>
          <Navbar
            courseName={course.name}
            mobileSidebarOpened={mobileSidebarOpened}
            navbarConfig={navbarConfig}
            onCloseMobileSidebar={() => setMobileSidebarOpened(false)}
          />
          <StyledLayoutMain>
            <Outlet context={{ course, finishCourse }} />
          </StyledLayoutMain>
        </>
      )}
    </Box>
  );
};
