// Local storage
export const ACCESS_TOKEN_LS_KEY = 'access_token';
export const REFRESH_TOKEN_LS_KEY = 'refresh_token';
export const THEME_MODE_LS_KEY = 'theme_mode';

// Http
export const AUTHORIZATION_TOKEN_PREFIX = 'Bearer';
export const TOKEN_EXPIRED_RESPONSE_MESSAGE = 'JWT token expired';
export const TOKEN_NOT_VALID_RESPONSE_MESSAGE = 'Access token corrupted';

export const LAST_MODULE_ID_LS_KEY = 'last_module_id';

export const LANGUAGES = {
  RU: 'ru',
  UA: 'ua',
  EN: 'en',
} as const;
