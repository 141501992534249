export const components = {
  notificationPopover: {
    title: 'Повідомлення',
    switchText: 'Показати тільки непрочитані',
    dividerToday: 'СЬОГОДНІ',
    dividerYesterday: 'ВЧОРА',
    dividerEarlier: 'РАНІШЕ',
    markUnreadButton: 'Позначити як непрочитане',
    empty: {
      title: 'Повідомлень поки немає',
      description: 'Ти побачиш нові повідомлення ось тут',
    },
    modal: {
      button: 'Ок, добре!',
    },
  },
  accountPopover: {
    logout: 'Вийти',
    home: 'Додому',
  },
  languagePopover: {
    en: 'US English',
    ru: 'Російська',
    ua: "Солов'їна",
  },
  modal: {
    confirm: 'Підтвердити',
    cancel: 'Скасувати',
  },
};
