import { LanguagePopover as GradualLanguagePopover } from 'gradual-system';

import { useLanguagesOptions } from '~components/Header/LanguagePopover/useLanguagesOptions';

export const LanguagePopover = () => {
  const { selectedLanguageOption, changeLanguageOption, options } = useLanguagesOptions();

  return (
    <GradualLanguagePopover
      selectedLanguageOption={selectedLanguageOption}
      onChangeLanguageOption={changeLanguageOption}
      options={options}
    />
  );
};
