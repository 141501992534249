import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { CourseApi } from '../courseApi';
import { CourseFinishProps } from '../courseTypes';

export const useCourse = (courseId: string) => {
  const { data: { data: course } = {}, isLoading } = useQuery(['course', { id: courseId }], () =>
    CourseApi.getCourse({ courseId }),
  );
  const queryClient = useQueryClient();

  const handleFinishCourse = useCallback(async ({ id, moduleId }: CourseFinishProps) => {
    await CourseApi.finishCourse({ id, moduleId });
  }, []);

  const { mutateAsync: finishCourse, isLoading: finishCourseLoading } = useMutation(handleFinishCourse, {
    onSettled: () => {
      queryClient.invalidateQueries(['course', { id: courseId }]);
      queryClient.invalidateQueries('courses');
    },
  });

  return {
    course,
    finishCourse,
    finishCourseLoading,
    courseLoading: isLoading,
  };
};
