export const course = {
  navbar: {
    title: 'Модули',
  },
  notFound: {
    title: 'Тренинг не найден',
    description: 'Пожалуйста, проверь ссылку или напиши админу',
    button: 'Домой',
  },
  overview: {
    buttonBack: 'Назад',
    title: 'Об этом тренинге',
    buttonBegin: 'Начать тренинг',
    modules: 'Количество модулей',
    quizzes: 'Количество тестов',
    tasks: 'Количество задач',
  },
  finished: {
    title: 'Ты закончил тренинг!',
    modules: 'Модулей пройдено',
    quiz: 'Средний результат тестов',
    time: 'Время прохождения',
    titleSocialMobile: 'Поделиться в соц.сетях',
    titleSocial: 'Поделиться сертификатом в соц.сетях',
    titleDownload: 'Загрузить .pdf версию сертификата',
    titleDownloadMobile: 'Загрузить .pdf',
    button: 'Просмотреть модули',
  },
};
