import { useTranslation } from 'react-i18next';
import { AccountPopover as GradualAccountPopover, useAuth } from 'gradual-system';

import { ROUTE_PATHS } from '~app/routes/paths';

export const AccountPopover = () => {
  const { user, logout } = useAuth();
  const { t } = useTranslation('components', { keyPrefix: 'accountPopover' });

  const MENU_OPTIONS = [
    {
      label: t('home'),
      linkTo: ROUTE_PATHS.INDEX,
    },
  ];

  return <GradualAccountPopover options={MENU_OPTIONS} user={user} onLogout={logout} logoutText={t('logout')} />;
};
