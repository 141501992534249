import { auth } from './auth';
import { components } from './components';
import { course } from './course';
import { courses } from './courses';
import { invalidCompany } from './invalidCompany';
import { module } from './module';
import { notFound } from './notFound';
import { oops } from './oops';
import { quiz } from './quiz';
import { task } from './task';

export default {
  auth,
  courses,
  components,
  quiz,
  task,
  course,
  notFound,
  invalidCompany,
  module,
  oops,
};
