import { createContext, FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader, useThemeMode } from 'gradual-system';

import { ROUTE_PATHS } from '~app/routes/paths';
import axios, { isAxiosError } from '~utils/axios';

const INVALID_SUBDOMAIN = 'INVALID_SUBDOMAIN';

export function getCompanyFromApi() {
  return axios.get('/learner/company/info');
}

export interface Company {
  color: string;
  logo: string;
  inviteType: string;
  name: string;
  adminEmail: string;
  customEmbedded?: string;
}

export const CompanyContext = createContext({} as Company);

export const CompanyProvider: FC = ({ children }) => {
  const [company, setCompany] = useState<Company>({} as Company);
  const [loading, setLoading] = useState<boolean>(true);
  const { themeMode } = useThemeMode();

  const navigate = useNavigate();

  const getCompany = useCallback(async () => {
    try {
      const { data } = await getCompanyFromApi();
      setCompany(data);
    } catch (e) {
      if (isAxiosError(e) && e?.response?.data?.messageCode === INVALID_SUBDOMAIN) {
        navigate(ROUTE_PATHS.INVALID_COMPANY);
      }
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCompany();
  }, [getCompany]);

  if (loading) {
    return <Loader color={themeMode === 'light' ? 'grey.300' : 'grey.100'} />;
  }

  return <CompanyContext.Provider value={company}>{children}</CompanyContext.Provider>;
};
