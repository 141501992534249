export const quiz = {
  prevButton: 'Prev question',
  mobilePrevButton: 'Prev',
  mobileNextButton: 'Next',
  mobileFinishButton: 'Finish',
  finishButton: 'Finish quiz',
  nextButton: 'Next question',
  description: 'Question {{questionNumber}}',
  of: 'of {{questionsLength}}',
  info: {
    title: 'Quiz Info',
    descriptionPassing: 'Passing score',
    descriptionAttempts: 'Number of attempts',
    descriptionAttemptsMobile: 'Attempts',
    descriptionTime: 'Time left',
  },
  navigation: {
    title: 'Quiz Navigation',
  },
  modal: {
    success: 'Success',
    failed: 'Failed',
    score: 'Your score',
    attemptsLeft: 'Attempts left',
    timeSpent: 'Time spent',
    tryAgainButton: 'Try again',
    backButton: 'Back to module',
    goodJob: 'Good job',
  },
  tabs: {
    question: 'Question',
    navigation: 'Navigation',
  },
  intro: {
    title: 'Overview',
    description: 'Quiz overview',
    passingScore: 'Passing score',
    requiresPassingScore: 'Requires Passing Score',
    attempts: 'Number of Attempts',
    questionsLength: 'Number of Questions',
    button: 'Begin quiz',
    backButton: 'Back to module',
    descriptionTime: 'Time limit',
  },
  openAnswer: {
    placeholder: 'Your answer',
  },
  toastSuccess: 'Quiz is finished',
  toastError: 'Oops! something went wrong...',
};
