import ReactDOM from 'react-dom';

import { App } from '~app/App';
import { AppProviders } from '~app/AppProviders/AppProviders';

import '~i18n';

interface CustomShadowOptions {
  filter: string;
  boxShadow: string;
}

declare module '@mui/material/styles' {
  interface Theme {
    customShadows: CustomShadowOptions;
  }
  interface ThemeOptions {
    customShadows: CustomShadowOptions;
  }

  interface TypographyVariants {
    text1: React.CSSProperties;
    text2: React.CSSProperties;
    text3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    text1?: React.CSSProperties;
    text2?: React.CSSProperties;
    text3?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    text1: true;
    text2: true;
    text3: true;
    h6: false;
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    caption: false;
    overline: false;
    inherit: false;
    button: false;
  }
}

ReactDOM.render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.querySelector('#root'),
);
