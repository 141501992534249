import { Link as RouterLink } from 'react-router-dom';

import { Link, ListItemText } from '@mui/material';

import { StyledNavbarListItem, StyledNavbarListItemIcon } from './navbarStyles';
import { NavbarConfigItem } from './navbarTypes';

export interface NavbarListItemProps {
  item: NavbarConfigItem;
  active?: boolean | undefined;
}

export const NavbarListItem = ({ item, active }: NavbarListItemProps) => {
  const { title, path, icon, disabled } = item;

  return (
    <Link to={path} component={RouterLink} sx={{ pointerEvents: disabled ? 'none' : ' auto' }}>
      <StyledNavbarListItem activeRoot={active}>
        <ListItemText sx={{ pr: 2 }} primary={title} />
        {icon && <StyledNavbarListItemIcon>{icon}</StyledNavbarListItemIcon>}
      </StyledNavbarListItem>
    </Link>
  );
};
