export const auth = {
  shared: {
    phoneLabel: 'Phone',
    emailLabel: 'Email address',
    passwordLabel: 'Password',
    backToSignIn: 'Back to Sign In',
  },
  validation: {
    emailRequired: 'Email is required',
    emailValid: 'Email must be a valid email address',
    lastNameRequired: 'Last name is required',
    nameRequired: 'Name is required',
    passwordConfirmationRequired: 'Password confirmation is required',
    passwordLength: 'Must have at least {{minPasswordLength}} characters',
    passwordRequired: 'Password is required',
    passwordsMatch: "Passwords don't match",
    phoneValid: 'Phone number is not valid',
    phoneRequired: 'Phone is required',
  },
  login: {
    description: "Don't have an account?",
    forgotPassword: 'Forgot password?',
    signUp: 'Sign Up',
    submitButton: 'Sign In',
    title: 'Welcome to {{name}}',
    titleDefault: 'Welcome to Gradual',
    training: 'Training!',
    subtitle: 'Enter email and password to access your account',
  },
  signUp: {
    buttonNext: 'Next step',
    confirmPasswordLabel: 'Confirm password',
    descriptionInfo: 'Type here your First and Last name',
    descriptionPassword: 'Must have at least {{minPasswordLength}} characters',
    haveAccount: 'Already have an account?',
    lastNameLabel: 'Last Name',
    nameLabel: 'Name',
    submitButton: 'Begin',
    titleEmail: 'Enter your email',
    titlePhone: 'Enter phone number',
    titleInfo: 'Enter your first and last name',
    titlePassword: 'Choose a password',
    signIn: 'Sign In',
  },
  forgotPassword: {
    description:
      'Please enter the email address associated with your account and We will email you a link to reset your password.',
    submitButton: 'Reset password',
    textSuccess: 'Request sent successfully',
    descriptionSuccess: 'We have sent a confirmation email. Please check your inbox.',
    title: 'Forgot your password?',
  },
  resetPassword: {
    description: 'Your password must be different to the previously used password',
    newPasswordConfirmLabel: 'Confirm new password',
    newPasswordLabel: 'New password',
    submitButton: 'Get a new password',
    title: 'Set a new password',
    textSuccess: 'Password reset',
    descriptionSuccess: 'Your password has been reset successfully. Please click below to log in.',
  },
};
