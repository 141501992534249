export const course = {
  navbar: {
    title: 'Modules',
  },
  notFound: {
    title: 'Course not found',
    description: 'Please, check your link or contact our admin',
    button: 'Back to Home',
  },
  overview: {
    buttonBack: 'Back to My Training',
    title: 'About this training',
    buttonBegin: 'Begin Training',
    modules: 'Number of Modules',
    quizzes: 'Number of Quizzes',
    tasks: 'Number of Tasks',
  },
  finished: {
    title: 'You have completed training',
    modules: 'Modules completed',
    quiz: 'Average Quiz result',
    time: 'Time spent',
    titleSocialMobile: 'Share to Socials',
    titleSocial: 'Share my Certificate in Social networks',
    titleDownload: 'Download .pdf version of Certificate',
    titleDownloadMobile: 'Download .pdf',
    button: 'View Modules',
  },
};
