import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { enUS, Localization, ruRU, ukUA } from '@mui/material/locale';

import { LANGUAGES } from '~constants';

import enFlag from './english-flag.svg';
import ruFlag from './russian-flag.svg';
import uaFlag from './ukrainian-flag.svg';

export interface LanguageOption {
  label: string;
  value: typeof LANGUAGES[keyof typeof LANGUAGES];
  systemValue: Localization;
  icon: string;
}

export function useLanguagesOptions() {
  const { i18n, t } = useTranslation('components', { keyPrefix: 'languagePopover' });
  const langStorage = localStorage.getItem('i18nextLng');

  const options: LanguageOption[] = useMemo(
    () => [
      {
        label: t('en'),
        value: LANGUAGES.EN,
        systemValue: enUS,
        icon: enFlag,
      },
      {
        label: t('ua'),
        value: LANGUAGES.UA,
        systemValue: ukUA,
        icon: uaFlag,
      },
      {
        label: t('ru'),
        value: LANGUAGES.RU,
        systemValue: ruRU,
        icon: ruFlag,
      },
    ],
    [t],
  );

  const selectedLanguageOption: LanguageOption = useMemo(
    () => options.find((lang) => lang.value === langStorage) || options[0],
    [langStorage, options],
  );

  const handleChangeLanguageOption = useCallback(
    (newLanguage: string) => {
      i18n.changeLanguage(newLanguage);
    },
    [i18n],
  );

  return {
    changeLanguageOption: handleChangeLanguageOption,
    selectedLanguageOption,
    options,
  };
}
