import { initReactI18next } from 'react-i18next';
import { i18n as i18nFromSystem } from 'gradual-system';
import i18next, { Resource } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';

import { LANGUAGES } from '~constants';

import en from './locales/en';
import ru from './locales/ru';
import ua from './locales/ua';

export const resources: Resource = {
  en,
  ru,
  ua,
} as const;

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    fallbackLng: LANGUAGES.EN,
    // Not needed for react as it escapes by default
    interpolation: {
      escapeValue: false,
    },
    resources,
  });

i18next.on('languageChanged', (lng) => {
  i18nFromSystem.changeLanguage(lng);
});

export default i18next;
