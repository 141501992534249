export const module = {
  quiz: {
    title: 'Тест',
    completed: 'Завершений',
    failed: 'Провалений',
    buttonStart: 'Розпочати',
    tryAgainButton: 'Перескласти',
    continueButton: 'Продовжити',
    newStatus: 'Новий',
    minutes: '{{min}} хв.',
    question: 'Питань: {{questionsLength}}',
    notFound: {
      title: 'Тесту не знайдено',
      description: 'Будь ласка, перевір посилання або сконтактуй адміна',
    },
    inProgress: 'У прогресі',
  },
  task: {
    title: 'Задача',
    submitted: 'Складена',
    buttonStart: 'Розпочати',
    tryAgainButton: 'Перескласти',
    newStatus: 'Нова',
    notFound: {
      title: 'Задачу не знайдено',
      description: 'Будь ласка, перевір посилання або сконтактуй адміна',
    },
  },
  notFound: {
    title: 'Модулю не знайдено',
    description: 'Будь ласка, перевір посилання або сконтактуй адміна',
    button: 'Додому',
  },
  buttons: {
    delay: 'Наступний модуль буде доступний через {{daysText}} {{hoursText}} {{minutesText}} {{secondsText}}',
    days: '{{days}} днів',
    hours: '{{hours}} годин',
    minutes: '{{minutes}} хвилин',
    seconds: '{{seconds}} секунд',
    prev: '< Попередній',
    next: 'Наступний >',
    finish: 'Завершити тренінг',
    view: 'Переглянути сертифікат',
  },
  pdfPanel: {
    of: 'of',
    button: 'Відкрити',
    fullSize: 'Відкрити в повному розмірі',
  },
  noContent: 'Здається, контенту немає',
  navbar: 'Модуль',
  toast: {
    error: 'Перезавантажте сторінку, щоб оновити тренінг',
  },
};
