import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useResponsive } from 'gradual-system';

import { Box, Drawer, Typography } from '@mui/material';

import { NAVBAR_MOBILE_RESOLUTION_BREAKPOINT, NAVBAR_WIDTH } from './navbarConstants';
import { NavbarList } from './NavbarList';
import { NavbarConfigItem } from './navbarTypes';

interface NavbarProps {
  mobileSidebarOpened: boolean;
  onCloseMobileSidebar: VoidFunction;
  navbarConfig: NavbarConfigItem[];
  courseName: string;
}

export const Navbar = ({ mobileSidebarOpened, onCloseMobileSidebar, navbarConfig, courseName }: NavbarProps) => {
  const isDesktop = useResponsive('up', NAVBAR_MOBILE_RESOLUTION_BREAKPOINT);
  const { t } = useTranslation('course', { keyPrefix: 'navbar' });

  const { pathname } = useLocation();

  useEffect(() => {
    if (mobileSidebarOpened) {
      onCloseMobileSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <>
      <Box
        sx={{
          pt: 30,
          px: 4,
        }}
      />

      <Typography pl={9} pr={9} mb={12} variant="h3" sx={{ wordWrap: 'break-word' }}>
        {courseName}
      </Typography>

      <Typography pl={9} variant="h3" mb={4}>
        {t('title')}
      </Typography>
      <NavbarList list={navbarConfig} />
    </>
  );
  return (
    <Box>
      {!isDesktop && (
        <Drawer
          variant="temporary"
          open={mobileSidebarOpened}
          onClose={onCloseMobileSidebar}
          PaperProps={{
            sx: {
              width: NAVBAR_WIDTH,
              backgroundColor: (theme) => (theme.palette.mode === 'light' ? 'grey.200' : 'grey.800'),
              backgroundImage: 'none',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          sx={{ width: NAVBAR_WIDTH }}
          PaperProps={{
            sx: {
              zIndex: 100,
              width: NAVBAR_WIDTH,
              backgroundColor: (theme) => (theme.palette.mode === 'light' ? 'grey.200' : 'grey.800'),
              border: 'none',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
};
