import { Outlet } from 'react-router-dom';
import { StyledLayoutMain } from 'gradual-system';

import { Header } from './Header/Header';

export const Layout = () => {
  return (
    <>
      <Header />
      <StyledLayoutMain>
        <Outlet />
      </StyledLayoutMain>
    </>
  );
};
