import { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import { AuthProvider } from './AuthProvider';
import { CompanyProvider } from './CompanyProvider';
import { ReactQueryClientProvider } from './ReactQueryClientProvider';
import { ThemeProvider } from './ThemeProvider';

export const AppProviders = ({ children }: { children: ReactNode }) => {
  return (
    <BrowserRouter>
      <SnackbarProvider>
        <ReactQueryClientProvider>
          <CompanyProvider>
            <ThemeProvider>
              <AuthProvider>{children}</AuthProvider>
            </ThemeProvider>
          </CompanyProvider>
        </ReactQueryClientProvider>
      </SnackbarProvider>
    </BrowserRouter>
  );
};
