export const task = {
  description: 'Опис:',
  yourAnswer: 'Твоя відповідь:',
  answerPlaceholder: 'Напиши щось...',
  uploadedFiles: 'Завантажені файли:',
  submitButton: 'Скласти',
  uploadButton: 'Завантажити файл',
  errorText: 'Декотрі файли важать більше 1GB',
  toastSuccess: 'Задача надіслана',
  toastError: 'Ой! щось пішло не так...',
  toastEmpty: 'Потрібно відповісти або прикріпити файл',
};
