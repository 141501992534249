export const courses = {
  title: 'My Training',
  empty: {
    title: 'You don’t have any training yet',
  },
  banner: {
    title: 'Greetings, {{firstName}}',
    description: 'New training is assigned. Please click on the card below to start.',
  },
  courseCard: {
    buttonStart: 'Start',
    buttonReview: 'Review',
    buttonContinue: 'Continue',
    module: 'Modules: {{count}}',
  },
};
