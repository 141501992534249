export const auth = {
  shared: {
    phoneLabel: 'Телефон',
    emailLabel: 'Email адрес',
    passwordLabel: 'Пароль',
    backToSignIn: 'Назад к входу',
  },
  validation: {
    emailRequired: 'Нужен Email',
    emailValid: 'Должен быть валидный Email адрес',
    lastNameRequired: 'Не хватает фамилии',
    nameRequired: 'Не хватает имени',
    passwordConfirmationRequired: 'Нужно подтвердить пароль',
    passwordLength: 'Должно быть как минимум {{minPasswordLength}} символов',
    passwordRequired: 'Нужен пароль',
    passwordsMatch: 'Пароли не совпадают',
    phoneValid: 'Номер телефона невалидный',
    phoneRequired: 'Нужен номер телефона',
  },
  login: {
    description: 'Нет аккаунта?',
    forgotPassword: 'Забыли пароль?',
    signUp: 'Зарегистрироваться',
    submitButton: 'Войти',
    title: 'Добро пожаловать в {{name}}',
    titleDefault: 'Добро пожаловать в Gradual',
    training: 'Тренинг!',
    subtitle: 'Введите адрес электронной почты и пароль для доступа к вашей учетной записи',
  },
  signUp: {
    buttonNext: 'Следующий шаг',
    confirmPasswordLabel: 'Подтверди пароль',
    descriptionInfo: 'Напиши имя и фамилию',
    descriptionPassword: 'Должно быть как минимум {{minPasswordLength}} символов',
    haveAccount: 'Есть аккаунт?',
    lastNameLabel: 'Фамилия',
    nameLabel: 'Имя',
    submitButton: 'Начать',
    titleEmail: 'Введи Email',
    titlePhone: 'Введи номер телефона',
    titleInfo: 'Введи имя и фамилию',
    titlePassword: 'Выбери пароль',
    signIn: 'Вход',
  },
  forgotPassword: {
    description: 'Введи email своего аккаунта. Мы пришлем ссылку для восстановления пароля',
    submitButton: 'Восстановить пароль',
    textSuccess: 'Запрос успешно отправлен',
    descriptionSuccess: 'Мы отправили email-подтверждение. Посмотри свой ящик.',
    title: 'Забыли пароль?',
  },
  resetPassword: {
    description: 'Выбери пароль, что не использовался ранее',
    newPasswordConfirmLabel: 'Подтверди новый пароль',
    newPasswordLabel: 'Новый пароль',
    submitButton: 'Получить новый пароль',
    title: 'Новый пароль',
    textSuccess: 'Пароль восстановлен',
    descriptionSuccess: 'Твой пароль был успешно восстановлен. Нажми ниже для входа',
  },
};
