import { ReactNode, useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useAuth } from 'gradual-system';

import axios from '~utils/axios';

import { ROUTE_PATHS } from './paths';

const acceptInvitation = (token: string) => {
  return axios.post(`/learner/invite/public/${token}`);
};

export const AuthGuard = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth();
  const [searchParam, setSearchParam] = useSearchParams();
  const queryClient = useQueryClient();

  const token = searchParam.get('token') || '';

  const handleAcceptInvitation = useCallback(async () => {
    try {
      await acceptInvitation(token);
      setSearchParam('');
      queryClient.invalidateQueries('courses');
    } catch (e) {
      //
      setSearchParam('');
    }
  }, [setSearchParam, token, queryClient]);

  useEffect(() => {
    if (token && user) {
      handleAcceptInvitation();
    }
  }, [token, user, handleAcceptInvitation]);

  if (!user) {
    return <Navigate to={ROUTE_PATHS.LOGIN} />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
