import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { Layout } from '~components/Layout';
import { ModuleLayout } from '~modules/module/ModuleLayout';

import { AuthGuard } from './AuthGuard';
import { GuestGuard } from './GuestGuard';
import { ROUTE_PATHS } from './paths';

const Login = lazy(() => import('~pages/LoginPage'));
const SignUp = lazy(() => import('~pages/SignUpPage'));
const ForgotPassword = lazy(() => import('~pages/ForgotPasswordPage'));
const ResetPassword = lazy(() => import('~pages/ResetPasswordPage'));
const Courses = lazy(() => import('~pages/CoursesPage'));
const Quiz = lazy(() => import('~pages/QuizPage'));
const Task = lazy(() => import('~pages/TaskPage'));
const Module = lazy(() => import('~pages/ModulePage'));
const Course = lazy(() => import('~pages/CoursePage'));
const NotFound = lazy(() => import('~pages/NotFoundPage'));
const InvalidCompany = lazy(() => import('~pages/InvalidCompanyPage'));
const CourseResult = lazy(() => import('~pages/CourseResultPage'));

export function Router() {
  return useRoutes([
    {
      element: <GuestGuard />,
      children: [
        { path: ROUTE_PATHS.LOGIN, element: <Login /> },
        { path: ROUTE_PATHS.SIGN_UP, element: <SignUp /> },
        { path: ROUTE_PATHS.FORGOT_PASSWORD, element: <ForgotPassword /> },
        { path: ROUTE_PATHS.RESET_PASSWORD, element: <ResetPassword /> },
      ],
    },
    {
      element: (
        <AuthGuard>
          <Layout />
        </AuthGuard>
      ),
      children: [
        { path: ROUTE_PATHS.INDEX, element: <Courses /> },
        { path: ROUTE_PATHS.COURSE, element: <Course /> },
        { path: ROUTE_PATHS.TASK, element: <Task /> },
        { path: ROUTE_PATHS.QUIZ, element: <Quiz /> },
        { path: ROUTE_PATHS.COURSE_RESULT, element: <CourseResult /> },
      ],
    },
    {
      element: (
        <AuthGuard>
          <ModuleLayout />
        </AuthGuard>
      ),
      children: [{ path: ROUTE_PATHS.MODULE, element: <Module /> }],
    },
    { path: ROUTE_PATHS.NOT_FOUND, element: <NotFound /> },
    { path: ROUTE_PATHS.INVALID_COMPANY, element: <InvalidCompany /> },
    { path: '*', element: <Navigate to={ROUTE_PATHS.NOT_FOUND} replace /> },
  ]);
}
