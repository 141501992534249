import { Suspense, useEffect, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Loader } from 'gradual-system';

import { Router } from '~app/routes/Router';
import { useCompany } from '~hooks/useCompany';
import OopsPage from '~pages/OopsPage';

// TODO: Consistent Border radius
export const App = () => {
  const { customEmbedded } = useCompany();
  const divRef = useRef<HTMLDivElement>(null);

  // TODO: Tmp solution for better development
  useEffect(() => {
    const DEFAULT_HOST = 'localhost:3000';
    const { host } = window.location;
    if (host === DEFAULT_HOST) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.location = `${window.location.protocol}//${process.env.REACT_APP_COMPANY}.${window.location.host}` as any;
    }
  }, []);

  useEffect(() => {
    if (customEmbedded) {
      const fragment = document.createRange().createContextualFragment(customEmbedded);
      divRef?.current?.append(fragment);
    }
  }, [customEmbedded]);

  return (
    <ErrorBoundary fallback={<OopsPage />}>
      <div ref={divRef} />
      <Suspense fallback={<Loader />}>
        <Router />
      </Suspense>
    </ErrorBoundary>
  );
};
