export const courses = {
  title: 'Мои тренинги',
  empty: {
    title: 'У тебя пока что нет тренингов',
  },
  banner: {
    title: 'Добро пожаловать, {{firstName}}',
    description: 'У тебя появились новые тренинги. Пожалуйста, нажмите на карту ниже, чтобы начать.',
  },
  courseCard: {
    buttonStart: 'Начать',
    buttonReview: 'Просмотреть',
    buttonContinue: 'Продолжить',
    module: 'Модулей: {{count}}',
  },
};
