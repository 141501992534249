import { createSearchParams, Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { useAuth } from 'gradual-system';

import { ROUTE_PATHS } from './paths';

export const GuestGuard = () => {
  const { user } = useAuth();
  const [searchParam] = useSearchParams();

  const token = searchParam.get('token') || '';

  if (user) {
    return (
      <Navigate
        to={{
          pathname: ROUTE_PATHS.INDEX,
          search: token && createSearchParams({ token }).toString(),
        }}
      />
    );
  }

  return <Outlet />;
};
